import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import styled from 'styled-components';
import { FieldArray } from 'react-final-form-arrays';
import { faPlus, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Button, ErrorMessage, Input, LabelButton, Select, StyledH1 } from '../../components/FormComponents';
import { Modal } from '../../components/Modal';
import { apiUrl } from '../../api';

const StyledForm = styled.form`
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-end;
    grid-gap: 10px;
    > ${LabelButton} {
        margin: auto auto auto 0;
    }
    > * {
        grid-column: 1;
    }
`;

const Buttons = styled.div`
    grid-column: 2;
    grid-row: 1/10;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 0 0 auto auto;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)``;

const Package = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 10px 0;
    grid-column: 1/3;
    width: 100%;
    background: ${({ theme: { menuBackground } }) => menuBackground};
    padding: 10px;
    border-radius: 20px;
    position: relative;
    ${StyledFontAwesomeIcon} {
        align-self: flex-end;
    }

    > ${Input} {
        grid-column: 1/3;
        margin: 0 auto;
        width: auto;
    }
    & > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 10px;
        > label {
            border-bottom: 1px solid #000;
            width: 100%;
            align-items: center;
            justify-content: center;
            padding-bottom: 5px;
            margin-bottom: 5px;
        }
    }
    & > div:first-of-type {
        border-right: 1px solid #000;
    }
    & > div:last-of-type {
        border-left: 1px solid #000;
    }
`;

const Devices = styled.div`
    margin: 10px 0;
    grid-column: 1/3;
    width: 100%;
    background: ${({ theme: { menuBackground } }) => menuBackground};
    display: flex;
    flex-wrap: wrap;
    & > div {
        width: 370px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px;
        position: relative;
    }
`;

const Specification = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    margin: 5px;
`;

const DevicePriceGrid = styled.div`
    display: grid;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    grid-gap: 5px;
    position: relative;
`;

const PriceWrapper = styled.div`
    display: flex;
    align-items: center;
    align-self: flex-start;
    span {
        margin-right: 10px;
    }
`;

const AbsoluteFontAwesomeIcon = styled(FontAwesomeIcon)`
    position: absolute;
    right: 10px;
    top: 10px;
`;

export const OfferForm = ({ onSubmit, initialValues }) => {
    const token = useSelector((store) => store.token);

    const [modal, setModal] = useState(null);
    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        axios
            .get(`${apiUrl}/offerCategory`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { categories: newCategories } }) => {
                setCategories(newCategories);
            });
        axios
            .get(`${apiUrl}/offerType`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { types: newTypes } }) => {
                setTypes(newTypes);
            });

        axios
            .get(`${apiUrl}/device/all`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { devices: newDevices } }) => {
                setDevices(newDevices);
            });
    }, [token, initialValues.id]);

    return (
        <>
            <Form
                onSubmit={onSubmit}
                initialValues={{ ...initialValues }}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators,
                }}
                render={({ handleSubmit }) => (
                    <StyledForm onSubmit={handleSubmit}>
                        <StyledH1>Edytuj ofertę</StyledH1>

                        <Field
                            name="categoryId"
                            render={({ input, meta }) => (
                                <>
                                    <Select {...input} disabled>
                                        <option value="">Kategoria</option>
                                        {categories.map(({ id: typeId, name }) => (
                                            <option value={typeId}>{name}</option>
                                        ))}
                                    </Select>
                                    {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                </>
                            )}
                        />
                        <Field
                            name="typeId"
                            render={({ input, meta }) => (
                                <>
                                    <Select {...input} disabled>
                                        <option value="">Oferta</option>
                                        {types.map(({ id: typeId, name }) => (
                                            <option value={typeId}>{name}</option>
                                        ))}
                                    </Select>
                                    {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                </>
                            )}
                        />

                        <FieldArray name="packageTypes">
                            {({ fields }) => (
                                <>
                                    {fields.map((name) => (
                                        <>
                                            <Field
                                                name={`${name}.id`}
                                                type="hidden"
                                                render={({ input }) => (
                                                    <>
                                                        <Input {...input} />
                                                    </>
                                                )}
                                            />

                                            <Field
                                                name={`${name}.name`}
                                                render={({ input }) => (
                                                    <>
                                                        <p>{input.value}</p>
                                                    </>
                                                )}
                                            />

                                            <FieldArray name={`${name}.packages`}>
                                                {({ fields: packagesFields }) => (
                                                    <>
                                                        <FontAwesomeIcon
                                                            onClick={() =>
                                                                packagesFields.push({
                                                                    name: '',
                                                                    versions: [
                                                                        { isYear: true, isActive: false },
                                                                        { isTwoYears: true, isActive: false },
                                                                    ],
                                                                })
                                                            }
                                                            icon={faPlus}
                                                        />
                                                        {packagesFields.map((packageName, packageIndex) => (
                                                            <Package>
                                                                <Field
                                                                    name={`${packageName}.id`}
                                                                    type="hidden"
                                                                    render={({ input }) => (
                                                                        <>
                                                                            <Input {...input} />
                                                                            <AbsoluteFontAwesomeIcon
                                                                                onClick={() => {
                                                                                    if (input.value) {
                                                                                        axios
                                                                                            .delete(
                                                                                                `${apiUrl}/package/${input.value}`,
                                                                                                {
                                                                                                    headers: {
                                                                                                        Authorization: `Bearer ${token}`,
                                                                                                    },
                                                                                                },
                                                                                            )
                                                                                            .then(() => {
                                                                                                packagesFields.remove(
                                                                                                    packageIndex,
                                                                                                );
                                                                                            });
                                                                                    } else {
                                                                                        packagesFields.remove(
                                                                                            packageIndex,
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                icon={faTimes}
                                                                            />
                                                                        </>
                                                                    )}
                                                                />
                                                                <Field
                                                                    name={`${packageName}.name`}
                                                                    render={({ input, meta }) => (
                                                                        <>
                                                                            <Input {...input} placeholder="Nazwa" />
                                                                            {meta.error && meta.touched && (
                                                                                <ErrorMessage>
                                                                                    {meta.error}
                                                                                </ErrorMessage>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                />

                                                                <FieldArray name={`${packageName}.versions`}>
                                                                    {({ fields: versionFields }) => (
                                                                        <>
                                                                            {versionFields.map((versionName, index) => (
                                                                                <div>
                                                                                    <Field
                                                                                        name={`${versionName}.id`}
                                                                                        type="hidden"
                                                                                        render={({ input }) => (
                                                                                            <>
                                                                                                <Input {...input} />
                                                                                            </>
                                                                                        )}
                                                                                    />
                                                                                    <Field
                                                                                        name={`${versionName}.isActive`}
                                                                                        type="checkbox"
                                                                                        render={({ input }) => (
                                                                                            <>
                                                                                                <>
                                                                                                    <Input
                                                                                                        {...input}
                                                                                                        id={`${versionName}-${index}`}
                                                                                                    />
                                                                                                </>
                                                                                            </>
                                                                                        )}
                                                                                    />
                                                                                    <Field
                                                                                        name={`${versionName}.isYear`}
                                                                                        type="checkbox"
                                                                                        render={({ input }) =>
                                                                                            input.checked ? (
                                                                                                <label
                                                                                                    htmlFor={`${versionName}-${index}`}
                                                                                                >
                                                                                                    12 miesięcy
                                                                                                </label>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )
                                                                                        }
                                                                                    />

                                                                                    <Field
                                                                                        name={`${versionName}.isTwoYears`}
                                                                                        type="checkbox"
                                                                                        render={({ input }) =>
                                                                                            input.checked ? (
                                                                                                <label
                                                                                                    htmlFor={`${versionName}-${index}`}
                                                                                                >
                                                                                                    24 miesiące
                                                                                                </label>
                                                                                            ) : (
                                                                                                <></>
                                                                                            )
                                                                                        }
                                                                                    />

                                                                                    <Field
                                                                                        name={`${versionName}.price`}
                                                                                        type="number"
                                                                                        render={({ input, meta }) => (
                                                                                            <PriceWrapper>
                                                                                                <span>
                                                                                                    Cena pakietu
                                                                                                </span>
                                                                                                <Input
                                                                                                    {...input}
                                                                                                    placeholder="cena"
                                                                                                    step="0.01"
                                                                                                />

                                                                                                {meta.error &&
                                                                                                    meta.touched && (
                                                                                                        <ErrorMessage>
                                                                                                            {meta.error}
                                                                                                        </ErrorMessage>
                                                                                                    )}
                                                                                            </PriceWrapper>
                                                                                        )}
                                                                                    />

                                                                                    <DragDropContext
                                                                                        onDragEnd={() => {}}
                                                                                    >
                                                                                        <Droppable droppableId="1">
                                                                                            {(provided) => (
                                                                                                <FieldArray
                                                                                                    name={`${versionName}.specification`}
                                                                                                    ref={
                                                                                                        provided.innerRef
                                                                                                    }
                                                                                                    {...provided.droppableProps}
                                                                                                >
                                                                                                    {({
                                                                                                        fields: specificationFields,
                                                                                                    }) => (
                                                                                                        <>
                                                                                                            {specificationFields.map(
                                                                                                                (
                                                                                                                    specificationName,
                                                                                                                    specificationIndex,
                                                                                                                ) => (
                                                                                                                    <Draggable
                                                                                                                        draggableId={
                                                                                                                            specificationName
                                                                                                                        }
                                                                                                                        index={
                                                                                                                            specificationName
                                                                                                                        }
                                                                                                                        key={
                                                                                                                            specificationName
                                                                                                                        }
                                                                                                                    >
                                                                                                                        {(
                                                                                                                            innerProvided,
                                                                                                                        ) => (
                                                                                                                            <Specification
                                                                                                                                {...innerProvided.draggableProps}
                                                                                                                                {...innerProvided.dragHandleProps}
                                                                                                                                ref={
                                                                                                                                    innerProvided.innerRef
                                                                                                                                }
                                                                                                                            >
                                                                                                                                <Field
                                                                                                                                    name={`${specificationName}.id`}
                                                                                                                                    type="hidden"
                                                                                                                                    render={({
                                                                                                                                        input,
                                                                                                                                    }) => (
                                                                                                                                        <>
                                                                                                                                            <Input
                                                                                                                                                {...input}
                                                                                                                                            />
                                                                                                                                        </>
                                                                                                                                    )}
                                                                                                                                />

                                                                                                                                <Field
                                                                                                                                    name={`${specificationName}.isMain`}
                                                                                                                                    type="checkbox"
                                                                                                                                    render={({
                                                                                                                                        input,
                                                                                                                                    }) => (
                                                                                                                                        <>
                                                                                                                                            <>
                                                                                                                                                <Input
                                                                                                                                                    {...input}
                                                                                                                                                    id={`${specificationName}`}
                                                                                                                                                />
                                                                                                                                                <label
                                                                                                                                                    htmlFor={`${specificationName}`}
                                                                                                                                                />
                                                                                                                                            </>
                                                                                                                                        </>
                                                                                                                                    )}
                                                                                                                                />

                                                                                                                                <Field
                                                                                                                                    name={`${specificationName}.name`}
                                                                                                                                    render={({
                                                                                                                                        input,
                                                                                                                                        meta,
                                                                                                                                    }) => (
                                                                                                                                        <>
                                                                                                                                            <Input
                                                                                                                                                {...input}
                                                                                                                                                placeholder="Wpisz nazwę"
                                                                                                                                            />
                                                                                                                                            {meta.error &&
                                                                                                                                                meta.touched && (
                                                                                                                                                    <ErrorMessage>
                                                                                                                                                        {
                                                                                                                                                            meta.error
                                                                                                                                                        }
                                                                                                                                                    </ErrorMessage>
                                                                                                                                                )}
                                                                                                                                        </>
                                                                                                                                    )}
                                                                                                                                />

                                                                                                                                <Field
                                                                                                                                    name={`${specificationName}.content`}
                                                                                                                                    render={({
                                                                                                                                        input,
                                                                                                                                        meta,
                                                                                                                                    }) => (
                                                                                                                                        <>
                                                                                                                                            <Input
                                                                                                                                                {...input}
                                                                                                                                                placeholder="Wartość"
                                                                                                                                            />
                                                                                                                                            {meta.error &&
                                                                                                                                                meta.touched && (
                                                                                                                                                    <ErrorMessage>
                                                                                                                                                        {
                                                                                                                                                            meta.error
                                                                                                                                                        }
                                                                                                                                                    </ErrorMessage>
                                                                                                                                                )}
                                                                                                                                        </>
                                                                                                                                    )}
                                                                                                                                />

                                                                                                                                <FontAwesomeIcon
                                                                                                                                    onClick={() =>
                                                                                                                                        specificationFields.remove(
                                                                                                                                            specificationIndex,
                                                                                                                                        )
                                                                                                                                    }
                                                                                                                                    icon={
                                                                                                                                        faTimes
                                                                                                                                    }
                                                                                                                                />
                                                                                                                            </Specification>
                                                                                                                        )}
                                                                                                                    </Draggable>
                                                                                                                ),
                                                                                                            )}

                                                                                                            <StyledFontAwesomeIcon
                                                                                                                onClick={() =>
                                                                                                                    specificationFields.push(
                                                                                                                        {
                                                                                                                            name:
                                                                                                                                '',
                                                                                                                            content:
                                                                                                                                '',
                                                                                                                        },
                                                                                                                    )
                                                                                                                }
                                                                                                                icon={
                                                                                                                    faPlus
                                                                                                                }
                                                                                                            />
                                                                                                        </>
                                                                                                    )}
                                                                                                </FieldArray>
                                                                                            )}
                                                                                        </Droppable>
                                                                                    </DragDropContext>
                                                                                </div>
                                                                            ))}
                                                                        </>
                                                                    )}
                                                                </FieldArray>
                                                            </Package>
                                                        ))}
                                                    </>
                                                )}
                                            </FieldArray>
                                        </>
                                    ))}
                                </>
                            )}
                        </FieldArray>

                        <FieldArray name="devices">
                            {({ fields: deviceFields }) => (
                                <>
                                    <p>Urządzenia</p>
                                    <FontAwesomeIcon onClick={() => deviceFields.push({})} icon={faPlus} />
                                    <Devices>
                                        {deviceFields.map((deviceName, deviceIndex) => (
                                            <div>
                                                <AbsoluteFontAwesomeIcon
                                                    onClick={() => {
                                                        deviceFields.remove(deviceIndex);
                                                    }}
                                                    icon={faTimes}
                                                />
                                                <Field
                                                    name={`${deviceName}.deviceId`}
                                                    render={({ input, meta }) => (
                                                        <>
                                                            <Select {...input}>
                                                                <option value="">Urządzenie</option>
                                                                {devices.map(({ id: deviceId, name }) => (
                                                                    <option value={deviceId}>{name}</option>
                                                                ))}
                                                            </Select>
                                                            {meta.error && meta.touched && (
                                                                <ErrorMessage>{meta.error}</ErrorMessage>
                                                            )}
                                                        </>
                                                    )}
                                                />

                                                <DevicePriceGrid>
                                                    <span />
                                                    <span>12 miesięcy</span>
                                                    <span>24 miesiące</span>

                                                    <span>Start</span>
                                                    <Field
                                                        name={`${deviceName}.start12`}
                                                        render={({ input, meta }) => (
                                                            <>
                                                                <Input {...input} placeholder="Cena" />
                                                                {meta.error && meta.touched && (
                                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${deviceName}.start24`}
                                                        render={({ input, meta }) => (
                                                            <>
                                                                <Input {...input} placeholder="Cena" />
                                                                {meta.error && meta.touched && (
                                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                                )}
                                                            </>
                                                        )}
                                                    />

                                                    <span>Advance</span>
                                                    <Field
                                                        name={`${deviceName}.advance12`}
                                                        render={({ input, meta }) => (
                                                            <>
                                                                <Input {...input} placeholder="Cena" />
                                                                {meta.error && meta.touched && (
                                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${deviceName}.advance24`}
                                                        render={({ input, meta }) => (
                                                            <>
                                                                <Input {...input} placeholder="Cena" />
                                                                {meta.error && meta.touched && (
                                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                                )}
                                                            </>
                                                        )}
                                                    />

                                                    <span>Success</span>
                                                    <Field
                                                        name={`${deviceName}.success12`}
                                                        render={({ input, meta }) => (
                                                            <>
                                                                <Input {...input} placeholder="Cena" />
                                                                {meta.error && meta.touched && (
                                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${deviceName}.success24`}
                                                        render={({ input, meta }) => (
                                                            <>
                                                                <Input {...input} placeholder="Cena" />
                                                                {meta.error && meta.touched && (
                                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                                )}
                                                            </>
                                                        )}
                                                    />

                                                    <span>Dopłata 10 zł</span>
                                                    <Field
                                                        name={`${deviceName}.ad1012`}
                                                        render={({ input, meta }) => (
                                                            <>
                                                                <Input {...input} placeholder="Cena" />
                                                                {meta.error && meta.touched && (
                                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${deviceName}.ad1024`}
                                                        render={({ input, meta }) => (
                                                            <>
                                                                <Input {...input} placeholder="Cena" />
                                                                {meta.error && meta.touched && (
                                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                                )}
                                                            </>
                                                        )}
                                                    />

                                                    <span>Dopłata 20 zł</span>
                                                    <Field
                                                        name={`${deviceName}.ad2012`}
                                                        render={({ input, meta }) => (
                                                            <>
                                                                <Input {...input} placeholder="Cena" />
                                                                {meta.error && meta.touched && (
                                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                    <Field
                                                        name={`${deviceName}.ad2024`}
                                                        render={({ input, meta }) => (
                                                            <>
                                                                <Input {...input} placeholder="Cena" />
                                                                {meta.error && meta.touched && (
                                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                                )}
                                                            </>
                                                        )}
                                                    />
                                                </DevicePriceGrid>
                                            </div>
                                        ))}
                                    </Devices>
                                </>
                            )}
                        </FieldArray>

                        <Buttons>
                            <Button>Zapisz</Button>
                            {initialValues.id && (
                                <Button secondary onClick={() => setModal(true)}>
                                    PDF
                                </Button>
                            )}
                        </Buttons>
                    </StyledForm>
                )}
            />
            {modal && <Modal>test</Modal>}
        </>
    );
};
