import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import Pagination from '@material-ui/lab/Pagination';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Table } from '../../components/Table';
import { StyledLink } from '../../components/Buttons';
import { Button, SearchInput } from '../../components/FormComponents';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { apiUrl } from '../../api';
import { Modal, ModalButtons } from '../../components/Modal';
import useDebounce from '../../hooks/debounce';
import { Permission } from '../../hooks/Permission';

const StyledTopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    input {
        margin-right: 15px;
    }
`;

export const PromoCodeList = () => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const [modalId, setModalId] = useState(null);
    const [change, setChange] = useState(0);
    const [items, setItems] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 1000, setPage);

    const token = useSelector((store) => store.token);

    useEffect(() => {
        axios
            .get(`${apiUrl}/promoCode`, {
                params: {
                    page,
                    search: debouncedSearchTerm,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { codes: newItems, lastPage } }) => {
                setItems(newItems);
                setPages(lastPage);
            });
    }, [token, page, change, debouncedSearchTerm]);

    return (
        <>
            <Permission permission="promoCode-index" />
            <PanelContentWrapper>
                <StyledTopWrapper>
                    <SearchInput onChange={(e) => setSearchTerm(e.target.value)} />
                    <StyledLink to="/kody-promocyjne/dodaj">Dodaj kody</StyledLink>
                </StyledTopWrapper>

                <Table>
                    <thead>
                        <tr>
                            <th>Kod</th>
                            <th>Status</th>
                            <th>Data od</th>
                            <th>Data do</th>
                            <th>Typ</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {items.map(({ id, code, status, from, to, isReusable }) => (
                            <tr key={id}>
                                <td>{code}</td>
                                <td>{status}</td>
                                <td>{from}</td>
                                <td>{to}</td>
                                <td>{isReusable ? 'Wielokrotnego użytku' : 'Jednorazowy'}</td>
                                <td>
                                    <FontAwesomeIcon
                                        onClick={() => {
                                            setModalId(id);
                                        }}
                                        icon={faTrash}
                                        fixedWidth
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Pagination
                    count={pages}
                    page={page}
                    size="large"
                    shape="rounded"
                    onChange={(event, pageNumber) => {
                        setPage(pageNumber);
                    }}
                />
            </PanelContentWrapper>

            {modalId && (
                <Modal
                    closeModal={() => {
                        setModalId(null);
                    }}
                >
                    <p>Czy na pewno usunąć produkt promocyjny?</p>
                    <ModalButtons>
                        <Button
                            onClick={() => {
                                axios
                                    .delete(`${apiUrl}/promoCode/${modalId}`, {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    })
                                    .then(() => {
                                        setChange(change + 1);
                                        setModalId(null);
                                    });
                            }}
                        >
                            Tak
                        </Button>
                        <Button
                            secondary
                            onClick={() => {
                                setModalId(null);
                            }}
                        >
                            Nie
                        </Button>
                    </ModalButtons>
                </Modal>
            )}
        </>
    );
};
