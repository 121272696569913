import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Pagination from '@material-ui/lab/Pagination';
import { Table } from '../../components/Table';
import { SearchInput } from '../../components/FormComponents';
import PanelContentWrapper from '../../components/PanelContentWrapper';
import { apiUrl } from '../../api';
import useDebounce from '../../hooks/debounce';
import { Permission } from '../../hooks/Permission';

const StyledTopWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    input {
        margin-right: 15px;
    }
`;

export const ContractList = () => {
    const [page, setPage] = useState(1);
    const [pages, setPages] = useState(1);
    const token = useSelector((store) => store.token);
    const [contracts, setContracts] = useState([]);

    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 1000, setPage);

    useEffect(() => {
        axios
            .get(`${apiUrl}/contract`, {
                params: {
                    page,
                    search: debouncedSearchTerm,
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { contracts: newContracts, lastPage } }) => {
                setContracts(newContracts);
                setPages(lastPage);
            });
    }, [token, page, debouncedSearchTerm]);

    return (
        <>
            <Permission permission="contract-index" />
            <PanelContentWrapper>
                <StyledTopWrapper>
                    <SearchInput onChange={(e) => setSearchTerm(e.target.value)} />
                </StyledTopWrapper>
                <Table>
                    <thead>
                        <tr>
                            <th>Numer umowy</th>
                            <th>Data/Godzina</th>
                            <th>Imię i nazwisko/Firma</th>
                            <th>Oferta</th>
                            <th>Status</th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                        {contracts.map(({ id, contractNumber, dateTime, fullName, offerType, status }) => (
                            <tr>
                                <td>{contractNumber}</td>
                                <td>{dateTime}</td>
                                <td>{fullName}</td>
                                <td>{offerType}</td>
                                <td>{status}</td>
                                <td>
                                    <Link to={`/umowy/${id}`}>
                                        <FontAwesomeIcon icon={faArrowRight} fixedWidth />
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <Pagination
                    count={pages}
                    page={page}
                    size="large"
                    shape="rounded"
                    onChange={(event, pageNumber) => {
                        setPage(pageNumber);
                    }}
                />
            </PanelContentWrapper>
        </>
    );
};
