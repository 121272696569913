import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Field, Form } from 'react-final-form';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Button, ErrorMessage, Input, LabelButton, StyledH1, TextArea } from '../../components/FormComponents';
import { apiUrl } from '../../api';
import PanelContentWrapper from '../../components/PanelContentWrapper';

const StyledForm = styled.form`
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: flex-end;
    grid-gap: 10px;
    > ${Button} {
        grid-column: 2;
        grid-row: 1;
        margin: auto 0 auto auto;
    }
    > ${LabelButton} {
        margin: auto auto auto 0;
    }
    > * {
        grid-column: 1;
    }
`;

const CheckBoxes = styled.div`
    display: flex;
    & > div {
        margin-right: 20px;
    }
`;

export const PromotionEdit = () => {
    const token = useSelector((store) => store.token);
    const [redirect, setRedirect] = useState(null);
    const { id } = useParams();
    const [item, setItem] = useState({
        isReusable: '0',
        isInfinite: '0',
    });

    useEffect(() => {
        axios
            .get(`${apiUrl}/promotion/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(({ data: { promotion: newItem } }) => {
                setItem({
                    ...newItem,
                    isReusable: '0',
                    isInfinite: '0',
                });
            });
    }, [id, token]);

    return (
        <>
            {redirect && <Redirect to={redirect} />}
            <PanelContentWrapper>
                <Form
                    onSubmit={(values) => {
                        axios
                            .put(`${apiUrl}/promotion/${id}`, values, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                            })
                            .then(() => {
                                setRedirect('/kody-promocyjne');
                                NotificationManager.success('Dodano kody promocyjne');
                            })
                            .catch(() => {
                                NotificationManager.error('spróbuj ponownie', 'Błąd ');
                            });
                    }}
                    initialValues={item}
                    render={({ handleSubmit, values, form }) => (
                        <StyledForm onSubmit={handleSubmit}>
                            <StyledH1>Dodaj kody promocyjne do promocji {item.name}</StyledH1>
                            <Field
                                name="name"
                                render={({ input, meta }) => (
                                    <>
                                        <Input {...input} placeholder="Nazwa" />
                                        {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                    </>
                                )}
                            />
                            <CheckBoxes>
                                <div>
                                    <Field
                                        name="isReusable"
                                        type="radio"
                                        value="0"
                                        render={({ input }) => (
                                            <>
                                                <Input {...input} id={`${input.name}-y`} />
                                                <label htmlFor={`${input.name}-y`}>Kod jednorazowy</label>
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Field
                                        name="isReusable"
                                        type="radio"
                                        value="1"
                                        render={({ input }) => (
                                            <>
                                                <Input {...input} id={`${input.name}-n`} />
                                                <label htmlFor={`${input.name}-n`}>Kod wielorazowy</label>
                                            </>
                                        )}
                                    />
                                </div>

                                <div>
                                    <Field
                                        name="isInfinite"
                                        type="radio"
                                        value="1"
                                        render={({ input }) => (
                                            <>
                                                <Input
                                                    {...input}
                                                    id={`${input.name}-y`}
                                                    onChange={(e) => {
                                                        input.onChange(e);
                                                        form.change('days');
                                                    }}
                                                />
                                                <label htmlFor={`${input.name}-y`}>Ważność nieskończona</label>
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name="isInfinite"
                                        type="radio"
                                        value="0"
                                        render={({ input }) => (
                                            <>
                                                <Input {...input} id={`${input.name}-n`} />
                                                <label htmlFor={`${input.name}-n`}>.</label>
                                            </>
                                        )}
                                    />
                                </div>
                                <div>
                                    <Field
                                        name="days"
                                        type="number"
                                        render={({ input, meta }) => (
                                            <>
                                                <Input
                                                    {...input}
                                                    placeholder="Ilość dni"
                                                    disabled={values.isInfinite === '1' ? 'disabled' : false}
                                                />
                                                {meta.error && meta.touched && (
                                                    <ErrorMessage>{meta.error}</ErrorMessage>
                                                )}
                                            </>
                                        )}
                                    />
                                </div>
                            </CheckBoxes>

                            <Field
                                name="codes"
                                render={({ input, meta }) => (
                                    <>
                                        <TextArea {...input} placeholder="Wpisz kody - jeden pod drugim" rows={20} />
                                        {meta.error && meta.touched && <ErrorMessage>{meta.error}</ErrorMessage>}
                                    </>
                                )}
                            />
                            <Button>Zapisz</Button>
                        </StyledForm>
                    )}
                />
            </PanelContentWrapper>
        </>
    );
};
